import classNames from "classnames";
import React, { useState } from "react";
import OpeningHoursCalculator from "@components/shared/OpeningHoursCalculator";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "moment/locale/is";
import moment from "moment";
import OpeningHoursForBranch from "@components/OpeningHoursForBranch/OpeningHoursForBranch";
export default function CompanyLocation({ location, specialOpenings }) {
    const [isOpen, setIsOpen] = useState(false);
    let nextWeekSpecialOpenings = specialOpenings?.filter((opening) => {
        return moment(opening.dags).isBefore(moment().add(7, "days"));
    });
    nextWeekSpecialOpenings?.forEach((opening) => {
        var weekday = moment(opening.dags).weekday();
        location.opening_hours.main[weekday] = {
            day: weekday,
            start: opening.open_time,
            end: opening.close_time,
        };
    });

    let calc = new OpeningHoursCalculator(location.opening_hours.main);

    return (
        <a>
            <div
                style={{
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <div className="d-flex">
                    <div className="d-flex align-items-center">
                        <div
                            className={classNames(
                                "location-open-indicator",
                                calc.nowIsBetweenTimes() ? "open" : "closed"
                            )}
                        />
                        <div>{calc.getOpenString()}</div>
                    </div>
                    <div className="flex-grow-1"></div>
                    <div className="d-flex align-items-center">
                        {isOpen ? (
                            <FontAwesomeIcon icon={faChevronUp} style={{ width: 12, height: 12 }} />
                        ) : (
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                style={{ width: 12, height: 12 }}
                            />
                        )}
                    </div>
                </div>
                {specialOpenings?.length > 0 && (
                    <div>
                        <div style={{ fontWeight: 600 }}>Sérstakir opnunartímar:</div>
                        {specialOpenings
                            ?.sort((a, b) => {
                                return a.date > b.date;
                            })
                            ?.map((day, index) => (
                                <div key={index}>
                                    {moment(day.date).format("dddd, D. MMMM YYYY")}:{" "}
                                    <span style={{ fontWeight: 600 }}>
                                        {day.closed
                                            ? "Lokað"
                                            : `${day.open_time}-${day.close_time}`}
                                    </span>
                                </div>
                            ))}
                    </div>
                )}

                <Collapse in={isOpen}>
                    <div>
                        <OpeningHoursForBranch branch={location} />
                    </div>
                </Collapse>
            </div>
        </a>
    );
}
