import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FlexContainer } from "./ui/flex_container";

mapboxgl.accessToken =
    "pk.eyJ1IjoiYmVyZ3Rob3IxODE5IiwiYSI6ImNreDZrODFtMjJmemcyeG9iYW54bHcwdmMifQ.89v_id_ZagcNU4aQ5LFXzQ";

export function MapBoxContainer(props) {
    var { markers } = props;
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(props.initialPos?.lng ? props.initialPos.lng : -18.9);
    const [currentMarkers, setCurrentMarkers] = useState([]);

    const [lat] = useState(props.initialPos?.lng ? props.initialPos.lat : 64.9);
    const [zoom] = useState(props.initialPos?.zoom ? props.initialPos.zoom : 4.5);

    // Initialize the map
    useEffect(() => {
        if (map?.current) return; // initialize map only once
        try {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [lng, lat],
                zoom: zoom,
            });
            map.current.on("load", function () {
                map.current.resize();
            });
            map.current.addControl(new mapboxgl.FullscreenControl());

            if (props.scrollDisabled) {
                map.current.scrollZoom.disable();
                map.current.addControl(new mapboxgl.NavigationControl());
            }

            map.current.addControl(
                new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: false,
                    },
                    // When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: false,
                    // Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: false,
                })
            );
        } catch (e) {
            console.log(e);
        }
    }, []);

    // Update the map with new markers.
    useEffect(() => {
        try {
            if (!markers) {
                return;
            }
            // Remove all the displayed markers.
            currentMarkers.forEach((marker) => {
                marker.remove();
            });
            setCurrentMarkers([]);

            var pins = [];
            var maxLat, maxLon, minLat, minLon;
            markers.forEach((marker) => {
                if (!marker || !marker?.lat || !marker?.lng) {
                    return;
                }
                if (marker.lat == 0 || marker.lng == 0) {
                    return;
                }

                if (isNaN(marker.lat) || isNaN(marker.lng)) {
                    return;
                }

                if (marker.lat > maxLat || maxLat === undefined) {
                    maxLat = marker?.lat;
                }
                if (marker.lat < minLat || minLat === undefined) {
                    minLat = marker.lat;
                }
                if (marker.lng > maxLon || maxLon === undefined) {
                    maxLon = marker.lng;
                }
                if (marker.lng < minLon || minLon === undefined) {
                    minLon = marker.lng;
                }
                // create a HTML element for each feature
                const el = getMarkerElement(marker);
                // make a marker for each feature and add to the map

                const mbMarker = new mapboxgl.Marker(el)
                    .setLngLat([marker.lng, marker.lat])
                    .addTo(map?.current);
                if (props.draggable) {
                    mbMarker.setDraggable(true);
                }
                function onDragEnd() {
                    marker = mbMarker.getLngLat();
                    props.onMarkerUpdated?.(marker);
                }

                mbMarker.on("dragend", onDragEnd);
                pins.push(mbMarker);
            }, []);
            setCurrentMarkers(pins);

            if (markers.length === 1) {
                if (!markers[0] || !markers[0]?.lat || !markers[0]?.lng) {
                    return;
                }
                map?.current?.setCenter([markers[0].lng, markers[0].lat]);
                map?.current?.setZoom(14);
            } else {
                if (maxLat && maxLon && minLat && minLon) {
                    let padding = 40;

                    const lonDelta = Math.abs(parseFloat(maxLon) - parseFloat(minLon));
                    const latDelta = Math.abs(parseFloat(maxLat) - parseFloat(minLat));

                    if (lonDelta < 0.001 && latDelta < 0.001) {
                        minLon = parseFloat(minLon) - 0.0015;
                        maxLon = parseFloat(maxLon) + 0.0015;
                        minLat = parseFloat(minLat) - 0.0015;
                        maxLat = parseFloat(maxLat) + 0.0015;
                    }

                    map?.current?.fitBounds(
                        [
                            [minLon, minLat], // southwestern corner of the bounds
                            [maxLon, maxLat], // northeastern corner of the bounds
                        ],
                        { padding }
                    );
                }
            }
        } catch (e) {
            console.log(e);
        }
    }, [markers]);

    return (
        <div ref={mapContainer} className="map-container" {...props}>
            <FlexContainer justifyContent="center" alignItems="center">
                Hleð korti inn...
            </FlexContainer>
        </div>
    );
}

function getMarkerElement(marker) {
    const mCenter = document.createElement("div");
    mCenter.className = "marker";
    mCenter.style.backgroundImage = `url(${marker.logo})`;

    const mContainer = document.createElement("div");
    mContainer.backgroundColor = "red";
    mContainer.className = "marker-container";

    const mContainerA = document.createElement("a");
    if (marker?.id) {
        mContainerA.target = "_blank";

        if (marker.belongs_to) {
            mContainerA.href = `/info/${marker.belongs_to}?id=${marker.id}`;
        } else {
            mContainerA.href = `/info/${marker?.id}`;
        }
    }

    if (!marker.logo) {
        mContainer.className = "marker-container-small";
        mCenter.className = "marker-small";
    }
    mContainer.appendChild(mCenter);
    mContainerA.appendChild(mContainer);
    mContainerA.ariaLabel = "Staðsetning fyrirtækis";
    return mContainerA;
}
