import { Table, Title } from "@components/ui";
import { FormatAddress } from "@helpers/Formatters";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import moment from "moment";
import React from "react";

function getNextDay(dayINeed) {
    dayINeed = dayINeed + 1;
    const today = moment().isoWeekday();

    // if we haven't yet passed the day of the week that I need:
    if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed).toISOString();
    } else {
        // otherwise, give me *next week's* instance of that same day
        return moment().add(1, "weeks").isoWeekday(dayINeed).toISOString();
    }
}

export default function OpeningHoursForBranch({ branch = {} }) {
    if (!branch.opening_hours) {
        return null;
    }
    const t = GetTranslatedStrings();
    let days = [
        { day: t.monday, timestamp: getNextDay(0), times: [] },
        { day: t.tuesday, timestamp: getNextDay(1), times: [] },
        { day: t.wednesday, timestamp: getNextDay(2), times: [] },
        { day: t.thursday, timestamp: getNextDay(3), times: [] },
        { day: t.friday, timestamp: getNextDay(4), times: [] },
        { day: t.saturday, timestamp: getNextDay(5), times: [] },
        { day: t.sunday, timestamp: getNextDay(6), times: [] },
    ];

    if (branch.opening_hours.main?.length > 0) {
        branch.opening_hours.main?.forEach?.((opening) => {
            if (opening.day < days.length) {
                days?.[opening?.day]?.times?.push({
                    open_time: opening.open_time ? opening.open_time : opening.start,
                    close_time: opening.close_time ? opening.close_time : opening.end,
                });
            }
        });
    } else {
        days = [];
    }

    const curr = new Date();
    var today = curr.getDay() - 1;
    if (today < 0) {
        today = 6;
    }
    days.sort((a, b) => {
        return a.timestamp.localeCompare(b.timestamp);
    });

    return (
        <div className="d-flex opening-hours" style={{ marginTop: 5, paddingTop: 5 }}>
            {/* <Title className="torgid-text-secondary" size={6}>
                {FormatAddress(branch.street, branch.house_number, branch.zip, branch.city)}
            </Title> */}

            <table className="w-100">
                <tbody>
                    {days.map((h, i) => {
                        return (
                            <tr key={i}>
                                <td
                                    className="px-0 torgid-text-primary"
                                    style={{ fontWeight: i == 0 && 600, paddingBottom: 6 }}
                                >
                                    {h.day}
                                </td>
                                <td
                                    className="torgid-text-primary"
                                    style={{ textAlign: "right", fontWeight: i == 0 && 600 }}
                                >
                                    {h.times.length != 0 &&
                                        h.times[0].open_time !== null &&
                                        h.times[0].close_time !== null &&
                                        h.times.map((time, index) => {
                                            return (
                                                <div key={index}>
                                                    {time?.open_time?.substring(0, 5)} -{" "}
                                                    {time?.close_time?.substring(0, 5)}
                                                    {index != h.times.length - 1 && <> og </>}
                                                </div>
                                            );
                                        })}
                                    {(h.times.length == 0 ||
                                        (h.times[0].open_time === null &&
                                            h.times[0].close_time === null)) && (
                                        <div
                                            className="torgid-text-secondary"
                                            style={{ fontStyle: "italic" }}
                                        >
                                            Lokað
                                        </div>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}
