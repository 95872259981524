import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import BorderedContainer from "@components/ui/bordered_container";
import { FlexContainer } from "@components/ui/flex_container";
import { PhoneNumber } from "./phone_number";
import { useRouter } from "next/router";
function EmployeeCell({ employee }) {
    const router = useRouter();

    const shouldHighlight =
        router.query.id !== undefined && parseInt(router.query.id) === parseInt(employee.id);
    return (
        <>
            <BorderedContainer className="p-2" style={{ backgroundColor: shouldHighlight }}>
                <FlexContainer justifyContent="between" style={{ flexWrap: "wrap" }}>
                    <div>
                        <div
                            style={{
                                fontWeight: 600,
                                fontSize: "18px",
                            }}
                        >
                            {employee.name}
                        </div>
                        <div
                            className="torgid-text-secondary"
                            style={{
                                fontSize: "15px",
                            }}
                        >
                            {employee.text}
                        </div>

                        {employee.emails && (
                            <a href={"mailto:" + employee.emails}>
                                <FontAwesomeIcon icon={faEnvelope} /> {employee.emails}
                            </a>
                        )}
                    </div>
                    <div style={{ flex: 1, justifySelf: "flex-end" }}>
                        {employee.phones?.map((phone) => {
                            return (
                                <PhoneNumber
                                    key={phone.phone}
                                    fontSize="20px"
                                    registrationID={employee.id}
                                    phone={phone}
                                />
                            );
                        })}
                    </div>
                </FlexContainer>
            </BorderedContainer>
        </>
    );
}
export default EmployeeCell;
