import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import React from "react";
import InfoSection from "./info_section";
export default function Socials({ socials, titleTextColor }) {
    if (!socials || socials.length === 0) {
        return <></>;
    }
    const t = GetTranslatedStrings();
    var hasSocials = false;
    Object.values(socials).forEach((element) => {
        if (element) {
            hasSocials = true;
        }
    });
    if (!hasSocials) {
        return <></>;
    }
    return (
        <>
            <InfoSection title={t.info_details_social_media} titleTextColor={titleTextColor}>
                <div className="d-flex justify-content-around">
                    <SocialPlatform
                        alt="Facebook"
                        icon="/img/social/facebook.svg"
                        link={socials?.facebook}
                    />
                    <SocialPlatform
                        alt="Twitter"
                        icon="/img/social/twitter.svg"
                        link={socials?.twitter}
                        style={{ marginTop: 3 }}
                    />
                    <SocialPlatform
                        alt="Instagram"
                        icon="/img/social/instagram.svg"
                        link={socials?.instagram}
                    />
                    <SocialPlatform
                        alt="YouTube"
                        icon="/img/social/youtube.svg"
                        link={socials?.youtube}
                    />
                    <SocialPlatform
                        alt="Pinterest"
                        icon="/img/social/pinterest.svg"
                        link={socials?.pinterest}
                    />
                    <SocialPlatform
                        alt="LinkedIn"
                        icon="/img/social/linkedin.svg"
                        link={socials?.linkedin}
                    />
                </div>
            </InfoSection>
        </>
    );
}

function SocialPlatform({ icon, link, style, alt }) {
    if (!link) {
        return <></>;
    }
    return (
        <a href={link} style={style} target="_blank" rel="noreferrer" aria-label={alt}>
            <img
                alt={alt}
                style={{
                    width: 35,
                    marginBottom: 0,
                    marginTop: 0,
                }}
                src={icon}
            />
        </a>
    );
}
