import Link from "next/link";
import React, { useEffect, useState } from "react";
import SkatturinnIcon from "@components/icons/SkatturinnIcon";
import { FormatKennitala, FormatWebsite } from "@helpers/Formatters";
import { MapBoxContainer } from "@components/MapBoxContainer";
import { FlexContainer } from "@components/ui/flex_container";
import EmployeeCell from "./employee_cell";
import InfoSection from "./info_section";
import ItemSummary from "./item_summary";
import { PhoneNumber } from "./phone_number";
import Socials from "./socials";
import { WEBPAGE_TITLE } from "@utils/constants";
import CompanyLocation from "./company_location";
import Head from "next/head";
import { Col, Row, Title } from "@components/ui";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { THEME_COLOR } from "@utils/constants";
import classNames from "classnames";
import { useRegistrationDetails } from "@hooks/1819";
import BranchCell from "@components/BranchCell/BranchCell";
import HorizontalAd from "@components/HorizontalAd/HorizontalAd";
import { useRouter } from "next/router";

export default function ItemDetails({
    item,
    infoSectionTitleTextColor,
    className,
    style,
    isPreviewing,
}) {
    if (!item) {
        return <></>;
    }
    const router = useRouter();
    const { data } = useRegistrationDetails(!isPreviewing ? item.id : null);
    if (data) {
        item = data;
    }
    const goToViolation = (id) => {
        const violation = document.getElementById(id);
        if (!violation) {
            return;
        }
        window.scrollTo({
            top: violation.offsetTop - 100,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        goToViolation(router.query.id);
    }, [data]);
    const t = GetTranslatedStrings();
    const [staffOpen] = useState(item?.staff?.length <= 2);
    const [branchesOpen] = useState(item?.branches?.length <= 3 || item.information == null);

    data?.branches?.forEach((branch) => {
        item.branches.forEach((itemBranch) => {
            if (itemBranch.id == branch.id) {
                itemBranch.opening_hours = branch.opening_hours;
            }
        });
    });

    var hasPhone = item?.phones?.length > 0;
    var hasWebsite = item?.websites?.length > 0;
    var hasEmail = item?.emails?.length > 0;
    var hasKennitala = item?.kennitala && item?.kennitala != 0;

    var markers = [];
    if (!!item?.latitude || !!item?.longitude) {
        markers = [{ lat: item?.latitude, lng: item?.longitude, logo: item?.logo_link }];
    }

    item?.branches?.forEach?.((branch) => {
        if (!!branch.latitude || !!branch.longitude) {
            markers.push({
                id: branch.id,
                belongs_to: branch.belongs_to,
                lat: branch.latitude,
                lng: branch.longitude,
                logo: branch.logo_link ? branch.logo_link : item?.logo_link,
            });
        }
    });

    var isOneColumn = !hasWebsite && !hasEmail && !hasKennitala;
    var hasBrands = item.tags_is?.brands && item.tags_is?.brands?.length != 0;
    var hasServices = item.tags_is?.services && item.tags_is?.services?.length != 0;

    return (
        <>
            <Head>
                <title key="site_title">
                    {item.name} - {WEBPAGE_TITLE}
                </title>
            </Head>

            <div className="row" style={style}>
                <div className={classNames("company-details", className)}>
                    <div>
                        {item?.head_link ? (
                            <a
                                href={item?.websites?.[0]}
                                style={{ minHeight: 228 }}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <picture>
                                    <img
                                        src={item?.head_link}
                                        className="cover-photo laufid-border"
                                    />
                                </picture>
                            </a>
                        ) : (
                            <HorizontalAd style={{ flex: 1 }} className="mx-auto" adPosition={2} />
                        )}
                    </div>

                    <ItemSummary company={item} />
                    <div className="row">
                        <div className="col-md-6">
                            {/* OPENING HOURS SECTION */}
                            <InfoSection
                                titleTextColor={infoSectionTitleTextColor}
                                title={t.info_details_opening_hours}
                                hidden={
                                    (!item?.is_company && !item?.opening_hours?.main) ||
                                    !item?.opening_hours?.main?.length
                                }
                                containerStyle={{ padding: "12px 15px" }}
                            >
                                <CompanyLocation
                                    location={item}
                                    specialOpenings={item?.opening_hours?.special}
                                />
                            </InfoSection>

                            {/* PHONE SECTION */}
                            <InfoSection
                                className="d-block d-sm-none"
                                hidden={!hasPhone}
                                title={
                                    item?.phones?.length === 1
                                        ? t.info_details_phone_singular
                                        : t.info_details_phone_plural
                                }
                                titleTextColor={infoSectionTitleTextColor}
                            >
                                {item?.phones?.map((phone) => {
                                    return (
                                        <PhoneNumber
                                            key={phone.phone}
                                            phone={phone}
                                            banBefore
                                            registrationID={item.id}
                                        />
                                    );
                                })}
                            </InfoSection>
                            {/* WEBSITE SECTION */}
                            <InfoSection
                                hidden={!hasWebsite}
                                titleTextColor={infoSectionTitleTextColor}
                                title={
                                    item?.websites?.length === 1
                                        ? t.info_details_website_singular
                                        : t.info_details_website_plural
                                }
                            >
                                {item?.websites?.map((website) => {
                                    if (!website.startsWith("http")) {
                                        website = "https://" + website;
                                    }

                                    return (
                                        <p
                                            key={website}
                                            style={{
                                                fontSize: "23px",
                                                margin: 0,
                                                padding: 0,
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <Link href={website || ""}>
                                                <a
                                                    target="_blank"
                                                    style={{
                                                        color: THEME_COLOR,
                                                    }}
                                                    rel="noreferrer"
                                                >
                                                    {FormatWebsite(website)}
                                                </a>
                                            </Link>
                                        </p>
                                    );
                                })}
                            </InfoSection>

                            {/* EMAIL SECTION */}
                            <InfoSection
                                hidden={!hasEmail}
                                titleTextColor={infoSectionTitleTextColor}
                                title={
                                    item?.emails?.length === 1
                                        ? t.info_details_email_singular
                                        : t.info_details_email_plural
                                }
                            >
                                {item?.emails?.map((email) => {
                                    return (
                                        <p
                                            key={email}
                                            style={{
                                                fontSize: "23px",
                                                margin: 0,
                                                padding: 0,
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <a
                                                href={`mailto:${email}`}
                                                style={{
                                                    color: THEME_COLOR,
                                                }}
                                            >
                                                {email}
                                            </a>
                                        </p>
                                    );
                                })}
                            </InfoSection>

                            {/* KENNITALA SECTION */}
                            <InfoSection
                                hidden={!hasKennitala}
                                titleTextColor={infoSectionTitleTextColor}
                                title="Kennitala"
                            >
                                <p
                                    style={{
                                        fontSize: "23px",
                                        margin: 0,
                                        padding: 0,
                                        marginBottom: "5px",
                                    }}
                                >
                                    <a
                                        target="_blank"
                                        href={
                                            !item.hide_keldan
                                                ? `https://keldan.is/Fyrirtaeki/Yfirlit/${item?.kennitala}`
                                                : `https://www.skatturinn.is/fyrirtaekjaskra/leit/kennitala/${item?.kennitala}`
                                        }
                                        style={{ color: THEME_COLOR }}
                                        rel="noreferrer"
                                    >
                                        {FormatKennitala(item?.kennitala)}
                                    </a>
                                </p>

                                {!item.hide_keldan && (
                                    <div className="d-flex">
                                        <img
                                            src="/img/keldan.png"
                                            height={23}
                                            style={{
                                                marginRight: 10,
                                                alignSelf: "center",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "20px",
                                                margin: 0,
                                                padding: 0,
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <a
                                                target="_blank"
                                                href={`https://keldan.is/Fyrirtaeki/Yfirlit/${item?.kennitala}`}
                                                style={{ color: THEME_COLOR }}
                                                rel="noreferrer"
                                            >
                                                {t.info_details_keldan}
                                            </a>
                                        </p>
                                    </div>
                                )}
                                {!item.hide_rsk && (
                                    <div className="d-flex">
                                        <SkatturinnIcon
                                            height={23}
                                            style={{
                                                marginRight: 10,
                                                alignSelf: "center",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "20px",
                                                margin: 0,
                                                padding: 0,
                                                marginBottom: "5px",
                                            }}
                                        >
                                            <a
                                                target="_blank"
                                                href={`https://www.skatturinn.is/fyrirtaekjaskra/leit/kennitala/${item?.kennitala}`}
                                                style={{ color: THEME_COLOR }}
                                                rel="noreferrer"
                                            >
                                                {t.info_details_fyrirtaekjaskra}
                                            </a>
                                        </p>
                                    </div>
                                )}
                            </InfoSection>
                        </div>

                        {/* ---------------------------------------------------------------- */}
                        <div className={isOneColumn ? "col-md-12" : "col-md-6"}>
                            <Socials
                                socials={{
                                    facebook: item?.facebook,
                                    instagram: item?.instagram,
                                    twitter: item?.twitter,
                                }}
                                titleTextColor={infoSectionTitleTextColor}
                            />
                            <InfoSection
                                title={t.info_details_map}
                                titleTextColor={infoSectionTitleTextColor}
                                hidden={markers.length === 0}
                                containerStyle={{ padding: 0 }}
                            >
                                <MapBoxContainer
                                    style={{
                                        position: "relative",
                                        minHeight: 300,
                                    }}
                                    markers={markers}
                                />
                            </InfoSection>
                        </div>
                    </div>
                    <InfoSection
                        title={t.info_details_tags}
                        collapsible
                        titleTextColor={infoSectionTitleTextColor}
                        hidden={
                            (!hasBrands && !hasServices) ||
                            (item.tags_is?.brands?.length == 0 &&
                                item.tags_is?.services?.length == 0)
                        }
                    >
                        <Row>
                            {hasBrands && (
                                <Col md={hasBrands && hasServices ? 6 : 12}>
                                    <Title size={5}>{t.info_details_brands}</Title>
                                    <FlexContainer style={{ flexWrap: "wrap" }}>
                                        {item.tags_is?.brands.map((tag) => {
                                            return (
                                                <Link href={"/?q=" + tag} key={tag}>
                                                    <div
                                                        className="company-tag"
                                                        style={{
                                                            backgroundColor: "#004DA1",
                                                        }}
                                                    >
                                                        <a>{tag.replace("-", " ")}</a>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </FlexContainer>
                                </Col>
                            )}
                            {hasServices && (
                                <Col md={hasBrands && hasServices ? 6 : 12}>
                                    <Title size={5}>{t.info_details_services}</Title>
                                    <FlexContainer style={{ flexWrap: "wrap" }}>
                                        {item.tags_is?.services.map((tag) => {
                                            return (
                                                <Link href={"/?q=" + tag} key={tag}>
                                                    <div
                                                        className="company-tag"
                                                        style={{
                                                            fontSize: 18,
                                                            backgroundColor: "#3a873d",
                                                        }}
                                                    >
                                                        <a>{tag.replace("-", " ")}</a>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </FlexContainer>
                                </Col>
                            )}
                        </Row>
                    </InfoSection>

                    <InfoSection
                        style={{ marginBottom: 20 }}
                        collapsible
                        titleTextColor={infoSectionTitleTextColor}
                        hidden={!item?.branches || item?.branches?.length === 0}
                        title={`${t.info_details_other_listings} (${item?.branches?.length})`}
                        items={item?.branches}
                        renderItem={(branch, index) => {
                            return (
                                <div style={{ padding: 0 }}>
                                    <BranchCell
                                        key={index}
                                        branch={branch}
                                        parentLogo={item.logo_link}
                                    />
                                </div>
                            );
                        }}
                    />
                    <InfoSection
                        style={{ marginBottom: 20 }}
                        collapsed={!staffOpen}
                        hidden={!item?.staff || item?.staff?.length === 0}
                        collapsible
                        titleTextColor={infoSectionTitleTextColor}
                        title={`${t.info_details_employees} (${item?.staff?.length})`}
                        items={item?.staff}
                        renderItem={(item, index) => {
                            return <EmployeeCell key={index} employee={item} />;
                        }}
                    />
                    <InfoSection
                        collapsible
                        hidden={!item.information}
                        titleTextColor={infoSectionTitleTextColor}
                        title={t.info_details_more_info}
                    >
                        <div
                            className="bordered-container-information"
                            dangerouslySetInnerHTML={{
                                __html: item?.information,
                            }}
                        />
                    </InfoSection>
                </div>
            </div>
        </>
    );
}
