import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { Title } from "@components/ui";
import BorderedContainer from "@components/ui/bordered_container";
import classNames from "classnames";
import { FlexContainer } from "@components/ui/flex_container";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function InfoSection({
    hidden,
    title,
    subtitle,
    children,
    collapsible,
    collapsed,
    titleSize,
    icon,
    items,
    renderItem,
    style,
    containerStyle,
    complementaryComponent,
    titleTextColor = "#000",
    className,
    containerClassName,
    onItemsReordered = () => {},
    draggable,
    ...props
}) {
    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    if (hidden) {
        return <></>;
    }
    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...items];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        onItemsReordered(updatedList);
    };

    return (
        <div
            {...props}
            style={{
                marginBottom: 20,
                ...style,
            }}
            className={className}
        >
            <div
                className="d-flex flex-row align-items-end justify-content-between flex-wrap"
                style={{ cursor: collapsible ? "pointer" : null }}
                onClick={() => {
                    setIsCollapsed(!isCollapsed);
                }}
            >
                <div className="d-flex flex-row">
                    <div className="d-flex" style={{ alignItems: "center" }}>
                        <span className="align-text-bottom">
                            {collapsible &&
                                (isCollapsed ? (
                                    <FontAwesomeIcon icon={faChevronDown} color={titleTextColor} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronUp} color={titleTextColor} />
                                ))}
                        </span>
                    </div>

                    {icon && (
                        <img src={icon} style={{ width: 30, height: 30, marginRight: 5 }}></img>
                    )}
                    <FlexContainer column>
                        <Title
                            size={titleSize ? titleSize : 5}
                            style={{
                                margin: 0,
                                paddingLeft: 10,
                                color: titleTextColor,
                            }}
                            className="company-location-group-title"
                        >
                            {title}
                        </Title>
                        {subtitle && (
                            <Title
                                size={6}
                                style={{
                                    margin: 0,
                                    paddingLeft: 10,
                                    color: titleTextColor + "ff",
                                    fontWeight: 400,
                                }}
                                className="company-location-group-title"
                            >
                                {subtitle}
                            </Title>
                        )}
                    </FlexContainer>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: 5,
                        flexGrow: 1,
                        justifyContent: "flex-end",
                    }}
                >
                    {complementaryComponent}
                </div>
            </div>

            <Collapse in={!collapsible || !isCollapsed}>
                {items && renderItem ? (
                    draggable ? (
                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="branch-container">
                                {(provided) => (
                                    <>
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <div
                                                className="row"
                                                style={{ marginLeft: 0, marginRight: 0 }}
                                            >
                                                {items.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    {renderItem(
                                                                        item,
                                                                        index,
                                                                        snapshot
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (
                        <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                            {items.map((item, index) => renderItem(item, index))}
                        </div>
                    )
                ) : (
                    <BorderedContainer
                        className={classNames(
                            containerClassName,
                            "bordered-container-small-sections"
                        )}
                        style={{ ...containerStyle }}
                    >
                        {children}
                    </BorderedContainer>
                )}
            </Collapse>
        </div>
    );
}
